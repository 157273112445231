import { useContext } from 'react'
import PropTypes from 'prop-types'

import { SitesContext } from 'context/SitesContextProvider'

import Logo from 'components/Logo'

import s from './ErrorTemplate.module.css'
import getCurrentSiteBusinessUnit from '../../helpers/getCurrentSiteBusinessUnit';

function ErrorTemplate({ title, description }) {
  const { currentSite } = useContext(SitesContext)
  return (
    <div className={s.container}>
      <Logo className={s.logo} colour="#fff" businessUnit={getCurrentSiteBusinessUnit()}/>
      <div className={s.info}>
        {title && <h1>{title}</h1>}
        {description && <p>{description}</p>}
        <span>
          Go to the
          <a href={currentSite?.path}>homepage</a>
        </span>
      </div>
    </div>
  )
}

ErrorTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}
ErrorTemplate.defaultProps = {
  title: '',
  description: '',
}

export default ErrorTemplate
